<template>
  <div class="teacher">
    <nav-top :nav="nav" ></nav-top>
    <el-form class="search" :inline="true" :model="query">
      <el-form-item label="标题：">
        <el-input class="input" placeholder="输入标题" clearable v-model.trim="query.title"></el-input>
      </el-form-item>
      <el-form-item label="日期范围：">
        <el-date-picker
          class="date"
          v-model="sdate"
          type="datetimerange"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="dateChange"
        ></el-date-picker>
      </el-form-item>
<!--      <el-form-item label="教师姓名：">-->
<!--        <el-input class="input" style="width: 180px" placeholder="输入教师姓名" clearable v-model.trim="query.ratabledName"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="search" class>查询</el-button>
      </el-form-item>
    </el-form>

    <div class="container" v-if="list.length">
      <el-row :gutter="20">
        <el-col :span="12" v-for="(item,index) in list" :key="index">
          <div class="card">
            <h4 :title="item.title">{{item.title}}</h4>
            <ul>
              <li>
                评价时间：
                <span>{{item.startTime}} - {{item.endTime}}</span>
              </li>
              <li>
                评价情况：
                <span>{{item.answerNum}} / {{item.retabledsNum}}</span>
              </li>
              <li>
                评价状态：
                <span :class="item.status | className">{{item.status==1?'完成':'未完成'}}</span>
              </li>
            </ul>
            <el-button class="button" type="primary" :plain="item.status==1" @click="goDetail(item)">{{item.status==1?'评价详情':'进入评价'}}</el-button>
          </div>
        </el-col>
      </el-row>
      <el-pagination
        :page-size="query.limit"
        :current-page="query.page"
        layout="total,prev, pager, next, jumper"
        :total="total"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <div v-else class="b-blank">
      <div class="img"></div>
      <p>暂无内容</p>
    </div>
  </div>
</template>
<script>
import api from '@/api/teacherEvat';
import NavTop from '@/components/NavTop.vue';
let briefCurIndex = null;

export default {
  components: {
    NavTop,
  },
  data() {
    return {
      query: {
        limit: 10,
        page: 1,
        startTime: '',
        endTime: '',
        title: '',
        ratabledName: '',
      },
      total: 0,
      pages: 1,
      list: [],
      show: true,
      nav: [
        {
          name: '共评价表',
          num: 0,
          img: require('@/assets/img/base/con_pjb.png'),
          status: 0,
        },
        {
          name: '已完成',
          num: 0,
          img: require('@/assets/img/base/con_ywc.png'),
          status: 0,
        },
        {
          name: '未完成',
          num: 0,
          img: require('@/assets/img/base/con_wwc.png'),
          status: 0,
        },
        {
          name: '已评价',
          num: 0,
          img: require('@/assets/img/base/con_gpjls.png'),
          status: 1,
        },
        {
          name: '待评价',
          num: 0,
          img: require('@/assets/img/base/con_dpjls.png'),
          status: 1,
        },
      ],
      sdate: '',
    };
  },
  created() {
    this.loadmovies();
    this.fetchData();
  },
  filters: {
    className(val) {
      if (val == 0) {
        return 'nostate';
      } else {
        return 'over';
      }
    },
  },
  methods: {
    fetchData() {
      if (briefCurIndex) {
        this.query.page = briefCurIndex;
      }
      api.search(this.query).then(response => {
        if (response.success) {
          this.list = response.result.records;
          this.total = response.result.total;
          this.pages = response.result.pages;
          this.query.limit = response.result.size;
        }
      });
    },
    loadmovies() {
      api.getAnalyNum().then(res => {
        let nums = res.result;
        nums.forEach((el,index) => {
          this.nav[index]['num'] = el;
        })
      });
    },
    search() {
      this.query.page = 1;
      this.query.limit = 10;
      this.fetchData();
    },
    dateChange(val) {
      if (val) {
        this.query.startTime = val[0];
        this.query.endTime = val[1];
      } else {
        this.query.startTime = '';
        this.query.endTime = '';
      }
    },
    handleCurrentChange(val) {
      this.query.page = val;
      briefCurIndex = val;
      this.fetchData();
    },
    goDetail(item) {
      this.$router.push({
        name: 'TeacherList',
        query: {
          paperId: item.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.teacher{
  min-height: 800px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.el-form{
  .date{
    width: 348px;
  }
}
.card {
  margin-bottom: 18px;
  border: 2px solid #f6f6fc;
  box-shadow: 8px 14px 18px 0px rgba(243, 245, 255, 0.72);
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  h4 {
    font-weight: normal;
    font-size: 20px;
    color: #343a52;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ul {
    margin-top: 20px;
    li {
      font-size: 16px;
      color: #a2a4aa;
      line-height: 2;
      span {
        color: #42475e;
      }
      span.nostate {
        color: #4691f3;
      }
      span.doing {
        color: #05b776;
      }
      span.over {
        color: #ff8400;
      }
    }
  }
  .el-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
.el-pagination{
  text-align: right;
  padding: 20px 0;
}
</style>
